import Vue from 'vue'
import Router from 'vue-router'
import VuejsDialog from "vuejs-dialog"
const routerOptions = [
    // ================================================ Root =======================================================
    // ================================================ Profile =======================================================
    { path: '/', name: 'UserProfile', componentPath: 'Profile/PersonalInfoView' },
    // { path: '/userprofile/:id', name: 'UserProfileById', componentPath: 'Profile/PersonalInfoView' },
    // ================================================ Organization and Companies =======================================================
    { path: '/company/createcompanyororganization', name: 'CreateCompany', componentPath: 'Companies/CreateCompany' },
    { path: '/organization/create', name: 'CreateOrganization', componentPath: 'Companies/CreateOrganization' },
    { path: '/organization/edit/:id', name: 'EditOrganization', componentPath: 'Companies/CreateOrganization' },
    { path: '/organization/createcompany/:id', name: 'CreateOrgCompany', componentPath: 'Companies/CreateStandaloneCompany' },
    { path: '/organization/createcompanys/:id/:org_name', name: 'CreateOrgCompany', componentPath: 'Companies/CreateStandaloneCompany' },
    // { path: '/organization/editcompany/:id', name: 'EditOrgCompany', componentPath: 'Companies/CreateStandaloneCompany' },
    { path: '/company/createcompany/standalonecompany', name: 'CreateStandaloneCompany', componentPath: 'Companies/CreateStandaloneCompany' },
    // { path: '/company/editcompany/standalonecompany/:id', name: 'EditStandaloneCompany', componentPath: 'Companies/CreateStandaloneCompany' },
    { path: '/company/view/cmpid/:company_id', name: 'ViewCompany', componentPath: 'Companies/ViewCompany' },
    { path: '/company/view/cmpids/:company_id/:org_name', name: 'ViewCompany', componentPath: 'Companies/ViewCompany' },
    { path: '/company/listview', name: 'CompaniesListView', componentPath: 'Companies/CompaniesListView' },
    { path: '/organization/listview', name: 'OrganizationListView', componentPath: 'Companies/OrganizationListView' },
    { path: '/company/createBranch/', name: 'CreateBranch', componentPath: 'Branch/CreateBranch' },
    // { path: '/company/createNewBranch/', name: 'CreateNewBranch', componentPath: 'Branch/CreateNewBranch' },
    // Company Bank Info
    { path: '/company/bank_info/cmpid/:company_id', name: 'BankDetailsInfo', componentPath: 'Companies/BankDetailsInfo' },
    // Company Outlet Details Info
    { path: '/company/outlet/cmpid/:company_id', name: 'OutletsInfo', componentPath: 'Companies/OutletsInfo' },
    { path: '/company/outlet/view/cmpid/:company_id/:id', name: 'ViewOutlets', componentPath: 'Companies/Outlets/ViewOutlets' },
    { path: '/linkemp/create', name: 'CreateLinkEmployee', componentPath: 'Companies/LinkEmployees/CreateLinkEmployee' },
    { path: "/companyinfo/view/cmpid", name: "ViewComapny", componentPath: 'Companies/CompanyInfo'},
    // ================================================ Employees =======================================================
    { path: '/employee/createemployee/cmpid/:company_id', name: 'CreateEmployee', componentPath: 'Employees/CreateEmployee' },
    { path: '/employee/viewemployee/cmpid/:company_id/:id', name: 'EditEmployee', componentPath: 'Employees/EditEmployee' },
    { path: '/employee/listemployee/cmpid/:company_id', name: "ListViewEmployee", componentPath: 'Employees/ListEmployeeProfile' },
    // ================================================ General/Companies Settings =======================================================
    { path: '/settings/general', name: 'GeneralSettings', componentPath: 'Settings/Settings'},
    { path: '/settings/profile', name: 'ProfileSettings', componentPath: 'Settings/Settings'},
    { path: '/company/settings/cmpid/:company_id', name: 'CompaniesSettings', componentPath: 'Settings/CompaniesSettings'},
    // ================================================ Business Units =======================================================
    { path: "/businessunit/listview/cmpid/:company_id", name: "BusinessUnitList", componentPath: 'BusinessUnit/BusinessUnitListView'},
    { path: "/businessunit/create/cmpid/:company_id", name: "CreateBusinessUnit", componentPath: 'BusinessUnit/CreateBusinessUnit'},
    { path: "/businessunit/view/cmpid/:company_id/:id", name: "ViewBusinessUnit", componentPath: 'BusinessUnit/ViewBusinessUnit'},
    { path: "/businessunit/edit/cmpid/:company_id/:id", name: "EditBusinessUnit", componentPath: 'BusinessUnit/CreateBusinessUnit'},
    // ================================================ Login/Register =======================================================
    { path: "/login", name: "Login", componentPath: 'Login/Login'},
    // { path: "/register", name: "Register", componentPath: 'Register/Register'},
    // ===================== Department ======================== //
    { path: "/departmentunit/listview/cmpid/:company_id", name:"DepartmentListView", componentPath: 'Department/DepartmentListView'},
    { path: "/departmentunit/create/cmpid/:company_id", name:"CreateDepartment", componentPath: 'Department/CreateDepartment'},
    { path: "/departmentunit/edit/cmpid/:company_id/:id", name:"EditDepartment", componentPath: 'Department/CreateDepartment'},
    { path: "/departmentunit/view/cmpid/:company_id/:id", name:"ViewDepartment", componentPath: 'Department/ViewDepartmentUnit'},
    // ==============  Product Access Pages ====================//
    { path: '/product/view', name: 'ProductAccessView', componentPath: 'ProductAccessView/ViewProfile' },
    { path: '/product/view/cmpid/:company_id', name: 'ProductAccessViewById', componentPath: 'ProductAccessView/ViewProfile' },
    { path: '/product/details/cmpid/:company_id/:id', name: 'SubscribedProduct', componentPath: 'ProductAccessView/ViewSingleProfile' },
    { path: "/user/verify", name: "VerifyUser", componentPath: 'VerifyUser' },
    { path: "/viewcontact/:id", name: "ViewContact", componentPath: 'ViewContact' },
    { path: "/verifyOtp", name: "OtpVerify", componentPath: 'OtpVerify' },
    // ============== Consulting Pages =========================//
    { path: '/consultants/listview/cmpid/:company_id', name: 'ConsultantListView', componentPath: "Consultant/ConsultantListView" },
    { path: '/consultants/createconsultant/cmpid/:company_id', name: 'CreateConsultant', componentPath: "Consultant/CreateConsultant" },
    { path: '/consultants/editconsultant/cmpid/:company_id/:id', name: 'EditConsultant', componentPath: "Consultant/CreateConsultant" },
    // ============== Investing Pages =========================//
    { path: '/investors/listview/cmpid/:company_id', name: 'InvestorsListView', componentPath: "Investors/investorsListView" },
    { path: '/investors/createinvestors/cmpid/:company_id', name: 'CreateInvestors', componentPath: "Investors/createInvestors" },
    { path: '/investors/editinvestors/cmpid/:company_id/:id', name: 'EditInvestors', componentPath: "Investors/createInvestors" },
    // ============== Bankers Pages =========================//
    { path: '/bankers/listview/cmpid/:company_id', name: 'BankersListView', componentPath: "Investors/bankerListView" },
    { path: '/bankers/createbankers/cmpid/:company_id', name: 'CreateBankers', componentPath: "Investors/createInvestors" },
    { path: '/bankers/editbankers/cmpid/:company_id/:id', name: 'EditBankers', componentPath: "Investors/createInvestors" },
    // ============== Suppliers Pages =========================//
    { path: '/suppliers/listview/cmpid/:company_id', name: 'SuppliersListView', componentPath: "Investors/supplierListView" },
    { path: '/suppliers/createsuppliers/cmpid/:company_id', name: 'CreateSuppliers', componentPath: "Investors/createInvestors" },
    { path: '/suppliers/editsuppliers/cmpid/:company_id/:id', name: 'EditSuppliers', componentPath: "Investors/createInvestors" },
    // ============== Board Member Pages =========================//
    { path: '/board/listview/cmpid/:company_id', name: 'BoardListView', componentPath: "BoardMeeting/BoardListView" },
    { path: '/board/createboard/cmpid/:company_id', name: 'CreateBoard', componentPath: "BoardMeeting/CreateBoard" },
    { path: '/board/editboard/cmpid/:company_id/:id', name: 'EditBoard', componentPath: "BoardMeeting/CreateBoard" },
    // { path: '/board/listview/cmpid/:company_id', name: 'BoardMemberListView', componentPath: "Investors/boardMemberListView" },
    // { path: '/board/createboard/cmpid/:company_id', name: 'CreateBoard', componentPath: "Investors/createInvestors" },
    // { path: '/board/editboard/cmpid/:company_id/:id', name: 'EditBoard', componentPath: "Investors/createInvestors" },
    // ============== Share Holder Pages =========================//
    { path: '/shareholder/listview/cmpid/:company_id', name: 'ShareholderListView', componentPath: "ShareHolder/ShareholderListView" },
    { path: '/shareholder/createshareholder/cmpid/:company_id', name: 'CreateShareholder', componentPath: "ShareHolder/CreateShareholder" },
    { path: '/shareholder/editshareholder/cmpid/:company_id/:id', name: 'EditShareholder', componentPath: "ShareHolder/CreateShareholder" },
    // { path: '/shareholder/listview/cmpid/:company_id', name: 'ShareHolderListView', componentPath: "Investors/shareholderListView" },
    // { path: '/shareholder/createshareholder/cmpid/:company_id', name: 'CreateShareholder', componentPath: "Investors/createInvestors" },
    // { path: '/shareholder/editshareholder/cmpid/:company_id/:id', name: 'EditShareholder', componentPath: "Investors/createInvestors" },
    // Access Denied
    { path: '/subscription', name: 'AccessDenied', componentPath: 'AccessDenied'},
    // ============== Profile Pages =========================//
    { path: '/PersonalInfoView/:id', name: 'PersonalInfoView', componentPath: "Profile/PersonalInfoView" },
    { path: '/CommunicationView/:id', name: 'CommunicationView', componentPath: "Profile/CommunicationView" },
    { path: '/AddressView/:id', name: 'AddressView', componentPath: "Profile/AddressView" },
    { path: '/PreferenceView/:id', name: 'PreferenceView', componentPath: "Profile/PreferenceView" },
    { path: '/HealthView/:id', name: 'HealthView', componentPath: "Profile/HealthView" },
    { path: '/RemindersView/:id', name: 'RemindersView', componentPath: "Profile/RemindersView" },
]
const routes = routerOptions.map(route => {
    return {
        ...route,
        component: () => import(`@/components/${route.componentPath}.vue`)
    }
})
Vue.use(Router)
Vue.use(VuejsDialog);
export default new Router({
    routes,
    base: process.env.BASE_URL,
    mode: 'history'
})